define("discourse/plugins/discourse-category-tokenization/discourse/templates/connectors/below-site-header/tokens-info-banner", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.shouldRender}}
    <TokensInfo />
  {{/if}}
  */
  {
    "id": "K5rnfrOQ",
    "block": "[[[41,[30,0,[\"shouldRender\"]],[[[1,\"  \"],[8,[39,1],null,null,null],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"tokens-info\"]]",
    "moduleName": "discourse/plugins/discourse-category-tokenization/discourse/templates/connectors/below-site-header/tokens-info-banner.hbs",
    "isStrictMode": false
  });
});