define("discourse/plugins/discourse-category-tokenization/discourse/connectors/category-navigation/tokens-info-banner", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // connectors/before-create-topic-button/tokens-info-banner.js

  class TokensInfoConnectorComponent extends _component.default {
    get shouldRender() {
      return this.siteSettings.plugin_position === "category-navigation";
    }
  }
  _exports.default = TokensInfoConnectorComponent;
});