define("discourse/plugins/discourse-category-tokenization/discourse/helpers/sub", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.sub = sub;
  function sub(params) {
    return params[0] - params[1] < 0 ? 0 : params[0] - params[1];
  }
  var _default = _exports.default = (0, _helper.helper)(sub);
});