define("discourse/plugins/discourse-category-tokenization/discourse/initializers/wizard-composer-initializer", ["exports", "discourse/lib/plugin-api", "discourse/plugins/discourse-category-tokenization/discourse/components/modal/control-message", "discourse-common/lib/get-url", "@ember/application", "@ember/service"], function (_exports, _pluginApi, _controlMessage, _getUrl, _application, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "zzz-wizard-composer-control",
    initialize(container) {
      (0, _pluginApi.withPluginApi)("0.8.36", api => {
        api.modifyClass("component:d-navigation", {
          pluginId: "custom-wizard",
          tokenDataService: (0, _service.inject)("token-data"),
          actions: {
            async clickCreateTopicButton() {
              let createTopicWizard = this.get("category.custom_fields.create_topic_wizard");
              if (!createTopicWizard) {
                this._super();
              } else {
                await this.tokenDataService.loadTokenData();
                let tokenData = this.tokenDataService.tokenData;
                if (tokenData.contestEnabled || tokenData.tokenizationEnabled) {
                  if (tokenData.contestEnabled && tokenData.totalReviews <= tokenData.reviewDone) {
                    window.location.href = (0, _getUrl.default)(`/w/${createTopicWizard}`);
                  } else if (tokenData.contestEnabled && tokenData.totalReviews > tokenData.reviewsDone) {
                    (0, _application.getOwner)(this).lookup("service:modal").show(_controlMessage.default, {
                      createTopicWizard: createTopicWizard,
                      tokenData: tokenData
                    });
                  } else if (tokenData.userHighestTrustLevel) {
                    window.location.href = (0, _getUrl.default)(`/w/${createTopicWizard}`);
                  } else if (tokenData.totalReviews <= tokenData.reviewsDone) {
                    window.location.href = (0, _getUrl.default)(`/w/${createTopicWizard}`);
                  } else {
                    (0, _application.getOwner)(this).lookup("service:modal").show(_controlMessage.default, {
                      createTopicWizard: createTopicWizard,
                      tokenData: tokenData
                    });
                  }
                } else {
                  window.location.href = (0, _getUrl.default)(`/w/${createTopicWizard}`);
                }
              }
            }
          }
        });
      });
    }
  };
});