define("discourse/plugins/discourse-category-tokenization/discourse/helpers/replace-text", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.replaceText = replaceText;
  function replaceText(_ref) {
    let [text = "", search, replacement] = _ref;
    return text.replace(search, replacement);
  }
  var _default = _exports.default = (0, _helper.helper)(replaceText);
});